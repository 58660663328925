import React from "react"
import PropTypes from "prop-types"
import tw, { styled } from "twin.macro"
import parse from "html-react-parser"
import { getSrc } from "gatsby-plugin-image"

const Container = styled.section`
  ${tw`grid grid-cols-1 gap-8 py-12  md:grid-cols-3 lg:py-24 px-skOffset`}
`
const Column = styled.div`
  ${tw`flex flex-col items-center mb-8  md:mb-0`}
`
const ColumnImage = styled.img`
  max-height: 220px;
`
const Description = styled.p`
  ${tw`w-2/3 mt-6 italic text-center text-gray-800  md:mt-10 font-copy xl:text-14 lg:text-12 text-14 lg:w-2/3 md:w-full`}
`

const WpAcfNumbersModuleBlock = ({ moduleData }) => {
  const blockData = moduleData.acfNumbersModuleBlock
  const sectionAttributes = moduleData.attributes
  const defaultId = `numbers-section-${Math.floor(Math.random() * 100)}`
  return (
    <Container id={`${sectionAttributes?.anchor || defaultId}`}>
      {blockData.columns.map((column, i) => {
        return (
          <Column key={`ns-col-${i}`}>
            <ColumnImage
              src={
                getSrc(column.image.localFile) ||
                column.image.localFile.publicURL
              }
              alt=""
            />
            <Description>{parse(column.description)}</Description>
          </Column>
        )
      })}
    </Container>
  )
}

WpAcfNumbersModuleBlock.propTypes = {
  moduleData: PropTypes.object,
}

WpAcfNumbersModuleBlock.defaultProps = {
  moduleData: ``,
}

export default WpAcfNumbersModuleBlock
