import { Link } from "gatsby"
import tw, { styled } from "twin.macro"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import parse from "html-react-parser"
import React from "react"

const NavigationPostContainer = styled.section`
  ${tw`pb-8  md:pb-12`}
`
const NavigationPostHeadline = styled.h2`
  ${tw`pt-24 pb-8 font-black text-black uppercase  px-skOffset text-20 lg:text-24 2xl:text-32`}
`
const CaseGridContainer = styled.div`
  ${tw`grid grid-cols-none gap-0  md:grid-cols-2`}
`
const Card = styled.div`
  ${tw`relative pb-8  md:pb-0`}
`
const CardImage = styled.div`
  ${tw`relative flex items-center justify-center `}
`
const CardHeadline = styled.p`
  ${tw`relative py-2 pl-10 italic text-left  font-copy text-14`}
  z-index: 1;
`

const WpCasesSinglePostNavigation = ({ moduleData }) => {
  const next = moduleData.next
  const prev = moduleData.prev
  const noInitialAni = null
  return (
    <NavigationPostContainer key="caseSection" id="cases-navigation-section">
      <NavigationPostHeadline>Noch nicht genug?</NavigationPostHeadline>
      <CaseGridContainer>
        {prev?.id && (
          <Card key={prev.id} className="md:col-start-1">
            <Link to={`/cases/${prev.slug}`} state={{ noInitialAni }}>
              <CardImage className="overflow-hidden h-50vw md:h-25vw">
                <GatsbyImage
                  image={
                    getImage(
                      prev.featuredImage.node?.localFile?.childImageSharp
                    ) ||
                    getImage(
                      prev.acfCasePostTypeTeaserFields.teaserImage?.localFile
                        ?.childImageSharp
                    )
                  }
                  alt=""
                />
              </CardImage>
              <CardHeadline>
                {parse(
                  prev.acfCasePostTypeTeaserFields?.teaserHeadline?.replace(
                    "<br>",
                    ""
                  ) || prev.title.replace("<br>", "")
                )}
              </CardHeadline>
            </Link>
          </Card>
        )}
        {next?.id && (
          <Card key={next.id} className="md:col-start-2">
            <Link to={`/cases/${next.slug}`} state={{ noInitialAni }}>
              <CardImage className="overflow-hidden h-50vw md:h-25vw">
                <GatsbyImage
                  image={
                    getImage(
                      next.featuredImage.node?.localFile?.childImageSharp
                    ) ||
                    getImage(
                      next.acfCasePostTypeTeaserFields.teaserImage?.localFile
                        ?.childImageSharp
                    )
                  }
                  alt=""
                />
              </CardImage>
              <CardHeadline>
                {parse(
                  next.acfCasePostTypeTeaserFields?.teaserHeadline?.replace(
                    "<br>",
                    ""
                  ) || next.title.replace("<br>", "")
                )}
              </CardHeadline>
            </Link>
          </Card>
        )}
      </CaseGridContainer>
    </NavigationPostContainer>
  )
}

WpCasesSinglePostNavigation.propTypes = {
  moduleData: PropTypes.object,
}

WpCasesSinglePostNavigation.defaultProps = {
  moduleData: ``,
}

export default WpCasesSinglePostNavigation
